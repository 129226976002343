<template>
    <div class="moreNews">
        <div>
            <img src="@/assets/img/about1.png" alt="" style="width:100%;height:auto;">
        </div>
        <div class="moreNews_content">
            <div class="menuButon">
                <div class="item" v-for="(item,index) in menuType" :key="index" :class="queryParame.type==item.value?'active':''" @click="clickNewsBtn(item.value)">{{item.key}}</div>
            </div>
            <div class="newsItems">
                <div class="oneNews" v-for="(item,index) in newslist" :key="index" @click="goDetail(item)">
                    <div class="newsl">
                        <img :src="item.image" alt="">
                    </div>
                    <div class="newsr">
                        <div class="date">
                            <div class="month">01</div>
                            <div class="year">2021</div>
                        </div>
                        <div class="texts">
                            <div class="title">{{item.title}}</div>
                            <div class="content">{{item.sTitle}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pages">
                <div class="onePage pre" @click="preClick">
                    <img src="@/assets/img/pagePre.png" width="8" height="10" alt="" >
                </div>
                <div class="onePage" v-for="(item,index) in allIndex" :key="index" :class="queryParame.pageIndex==item?'active':''" @click="clickIndex(item)">{{item}}</div>
                <div class="onePage next" @click="nextClick">
                    <img src="@/assets/img/pageNext.png" width="8" height="10" alt="">
                </div>
                <div style="margin-left:20px;">
                    8条/页
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import selects from '@/api/selects.js'
import news from '@/api/news.js'
export default {
    data(){
        return {
            queryParame:{
                type: 21,
                pageIndex: 1,
                pageSize: 8
            },
            total:0,
            allIndex:[],
            menuType:[],
            newslist:[]
            
        }
    },
    mounted(){
        // this.initMenu()
        // this.init()
    },
    methods: {
        initMenu(){
            selects.QueryParameterItem({code:'103'}).then(resp=>{
                if(resp.data.code == 200){
                    this.menuType = []
                    for(let item of resp.data.data.data){
                        this.menuType.push({
                            key: item.key,
                            value: parseInt(item.value) 
                        })
                    }
                    console.log(this.menuType,"类型2")
                    this.init()
                }
                
            })
        },
        init(){
            news.Query(this.queryParame).then(resp=>{
                if(resp.data.code == 200){
                    this.newslist = resp.data.data.home
                    this.total = resp.data.data.total
                    console.log(this.newslist,"新闻",)
                    console.log("总数",this.total,",每页8")
                    let xushu = this.total%this.queryParame.pageSize
                    let chushu = parseInt(this.total/this.queryParame.pageSize)
                    let allIndex = chushu
                    if(xushu>0){
                        allIndex+=1
                    }
                    this.allIndex = []
                    for(let i=1;i<allIndex+1;i++){
                        this.allIndex.push(i)
                    }
                    console.log("%",xushu)
                    console.log("/",chushu)
                    console.log("总页数",this.allIndex)
                }
            })
        },
        clickNewsBtn(e){
            console.log(e,"这话")
            this.queryParame.type = e
            this.queryParame.pageIndex = 1
            this.init()
            // this.activeImg = require(`@/assets/img/build${e}.png`)
        },
        goDetail(item){
            this.$router.push({
                name:"newsDetail",
                query: {
                    detail: JSON.stringify(item)
                }
            })
        },
        clickIndex(e){
            this.queryParame.pageIndex = e
            this.init()
        },
        preClick(){
            if(this.queryParame.pageIndex>1){
                this.queryParame.pageIndex--
                this.init()
            }
        },
        nextClick(){
            if(this.queryParame.pageIndex<this.allIndex.length){
                this.queryParame.pageIndex++
                this.init()
            }
        }
    }
}
</script>
<style lang="less" scoped>
.moreNews {
    &_content {
        width: 1200px;
        // width: 60%;
        // min-width: 1200px;
        margin: 0 auto;
        padding: 30px 0;
        .menuButon {
            display: flex;
            text-align: left;
            .item {
                cursor: pointer;
                font-size: 24px;
                color: rgba(51, 51, 51, 1);
                margin-right: 20px;
                padding: 10px 0;
                &.active {
                    font-weight: bold;
                    border-bottom: 4px solid rgba(40, 123, 255, 1);
                }
            }
        }
        .newsItems {
            margin: 20px 0;
            .oneNews {
                cursor: pointer;
                display: flex;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px dashed rgba(177, 177, 177, 1);
                .newsl {
                    width: 250px;
                    height: 150px;
                    background: #eee;
                    img {
                        width: auto;
                        height: 100%;
                    }
                }
                .newsr {
                    display: flex;
                    margin-left: 20px;
                    .date {
                        font-size: 24px;
                        width: 100px;
                        color: rgba(102, 102, 102, 1);
                        .month {
                            text-decoration: underline;
                        }
                        .year {
                            font-size: 14px;
                        }
                    }
                    .texts {
                        text-align: left;
                        font-size: 14px;
                        color: rgba(51, 51, 51, 1);
                        .title {
                            font-weight: bold;
                            // height: 24px;
                            line-height: 30px;
                            white-space: wrap;
                        }
                    }
                }
            }
            
        }
        .pages {
            margin: 40px 0;
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: flex-end;
            .onePage {
                cursor: pointer;
                margin-left: 20px;
                width: 27px;
                height: 27px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid rgba(0, 0, 0, 1);
                &.active {
                    background: #000;
                    color: white;
                }
            }
            .pre,.next {
                border-radius: 50%;
            }
        }
    }
}
</style>